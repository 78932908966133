import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import {
    Row,
    Col,
    Card,
    Form,
    Spin,
    Table,
    Input,
    Button,
    Popconfirm,
    Modal,
    Select,
    Divider,
    InputNumber,
    DatePicker,
    Tag,
    message
} from 'antd';
import { delay } from '../../helpers/helpers';
import './users-tab.scss';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/users';
import { CreateUserRoles, DeleteUserRoles } from '../../constants/roles';

const { Option } = Select;
const { RangePicker } = DatePicker;

const UsersTab = () => {

    const params = useParams();
    const [filteredData, setFilteredData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [generalDepartmentId, setGeneralDepartmentId] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [roleExtra, setRoleExtra] = useState("");
    const [loading, setLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [activateLoading, setActivateLoading] = useState(false);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({});
    const [departments, setDepartments] = useState([]);
    const currentUser = useSelector((state) => state.auth.currentUser);
    var ocrRoleName = currentUser?.ocrRole?.name;
    var ocrRolePower = currentUser?.ocrRole?.power;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [updatingUser, setUpdatingUser] = useState({});

    const [createForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const showCreateModal = () => {
        setCreateModalOpen(true);
    };
    const handleCreateOk = () => {
        createForm.submit();
    };
    const handleCreateCancel = () => {
        setCreateModalOpen(false);
    };

    const showUpdateModal = async (record) => {
        console.log("Edit Record", record);
        setUpdatingUser(record);

        updateForm.setFieldsValue(record);
        setUpdateModalOpen(true);
    };

    const handleUpdateOk = () => {
        updateForm.submit();
    };


    const handleDepartmentChange = (e) => {
        console.log("Department Element", e);
    }

    const handleRoleChange = (e) => {
        console.log("Role Element", e);
        const foundRole = roleData.find(r => r.id == e);

        if (foundRole) {
            const description = foundRole.description;
            setRoleExtra(description);
        }
    }

    const handleUpdateCancel = () => {
        setUpdateModalOpen(false);
    };

    useEffect(() => {
        document.title = 'Roxus | Manage Users';
        fetchRoles();
        fetchDepartments();
        fetchTable();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addUser = async (values) => {

        values.companyId = params.companyId;
        values.isActivated = false;
        console.log('Create Users: ', JSON.stringify(values));

        let createUserRequest = values;

        try {
            setCreateLoading(true);
            const response = await axiosConfig({
                url: `/api/nanonets/users`,
                data: createUserRequest,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Create User SUCCESSFULLY!');
            await fetchTable();
            setCreateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setCreateLoading(false);
        }
    };

    const updateUser = async (values) => {

        console.log('Update User: ', values);
        let updateUserRequest = values;
        // return;

        try {
            setUpdateLoading(true);
            const response = await axiosConfig({
                url: `/api/nanonets/users/${updatingUser.key}`,
                data: updateUserRequest,
                method: 'put',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Update User SUCCESSFULLY!');
            await fetchTable();
            setUpdateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }

    };

    const fetchTable = async () => {
        await axiosConfig({
            method: 'get',
            url: `/api/settings/companies/${params.companyId}/users`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                console.log(response);
                const data = response.data.data.map((res, index) => {
                    index++;
                    var userDepartments = res.departments;
                    
                    var newDepartments = userDepartments.map(d => {
                        return d.id;
                    });

                    var departmentNames = [];
                    departmentNames = userDepartments.map(d => {
                        return d.name;
                    });

                    return {
                        key: res.id,
                        no: res.id,
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email,
                        isActivated: res.isActivated,
                        phoneNumber: res.phoneNumber,
                        departments: newDepartments,
                        department: departmentNames,
                        jobTitle: res.jobTitle,
                        ocrRoleId: res.ocrRoleId,
                        ocrRole: res.ocrRole?.name,
                        ocrRolePower: res.ocrRole?.power,
                        mailingStreet: res.mailingStreet,
                        mailingCity: res.mailingCity,
                        mailingState: res.mailingState,
                        mailingZip: res.mailingZip,
                        createdTime: res.createdDate,
                        modifiedTime: res.modifiedDate,
                    };
                });
                console.log('Table Data', data);
                setTableData(data);
                setFilteredData(data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                setLoading(false);
                console.log(error.config);
            });
    };

    const fetchDepartments = async () => {
        await axiosConfig({
            method: 'get',
            url: `/api/settings/companies/${params.companyId}/departments`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {

                const departments = response.data.data;
                const data = departments.map((department, index) => {
                    index++;
                    return {
                        key: department.id,
                        id: department.id,
                        createdDate: department.createdDate,
                        name: department.name
                    };
                });
                setDepartmentData(data);

                const departmentOptions = departments.map((department, index) => {
                    var departmentId = department.id;
                    var departmentName = department.name;
                    if (departmentName == "General")
                    {
                        setGeneralDepartmentId(departmentId);
                    }
                    return {
                        value: departmentId,
                        label: departmentName,
                    }
                });
                setDepartmentOptions(departmentOptions);

                console.log('Department Data', data);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                setLoading(false);
                console.log(error.config);
            });
    };

    const fetchRoles = async () => {
        await axiosConfig({
            method: 'get',
            url: `/api/nanonets/roles`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                console.log("Get Roles Response", response);
                const roles = response.data.data;
                const data = roles.map((role, index) => {
                    index++;
                    return {
                        key: role.id,
                        id: role.id,
                        createdDate: role.createdDate,
                        name: role.name,
                        description: role.description
                    };
                });
                setRoleData(data);

                const roleOptions = roles.map((role, index) => {
                    let disabled = false;
                    if (role.power > ocrRolePower) {
                        disabled = true;
                    }
                    return {
                        value: role.id,
                        label: role.name,
                        disabled: disabled
                    }
                });
                setRoleOptions(roleOptions);

                console.log('Role Data', data);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                setLoading(false);
                console.log(error.config);
            });
    };

    const removeUser = async (userId) => {
        await axiosConfig({
            method: 'delete',
            url: `/api/settings/licenses/${userId}`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                console.log(response);
                fetchTable();
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };

    const applySearchCriteria = () => {
        let filterData = tableData;
        if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
            filterData = filterData.filter(
                (row) =>
                    row.Name
                        .toLowerCase()
                        .indexOf(searchCriteria.keyword.toLowerCase()) > -1
            );
            setFilteredData(filterData);
        }
        setFilteredData(filterData);
    };

    const filterByKeyword = (e) => {
        delay(function () {
            setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
        }, 500);
    };

    const deleteUser = async (userId) => {
        try {
            setUpdateLoading(true);
            await axiosConfig({
                url: `/api/nanonets/users/${userId}`,
                method: 'delete',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            })
                .then((response) => {
                    console.log(`response`, response);
                    message.success('Delete User Successfully!');
                })
            await fetchTable();
        } catch (error) {
            console.log(`error`, error);
            message.error("Delete User Failed!");
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }
    }

    const activateUser = async (userId) => {
        try {
            setUpdateLoading(true);
            await axiosConfig({
                url: `/api/nanonets/users/${userId}/activate`,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            })
                .then((response) => {
                    console.log(`response`, response);
                    message.success('Send Activation Email to user SUCCESSFULLY!');
                })
            // await fetchTable();
            setUpdateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }
    }

    const columns = [
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                let editDisabled = true;
                if (ocrRolePower && record.ocrRolePower && ocrRolePower >= record.ocrRolePower) {
                    editDisabled = false;
                }
                return (
                    <>
                        {
                            <Button disabled={editDisabled} onClick={async () => await showUpdateModal(record)}>
                                Edit
                            </Button>
                        }
                        {ocrRoleName && DeleteUserRoles.includes(ocrRoleName) &&
                            <Popconfirm
                                placement='top'
                                title={'Are you sure to delete this user?'}
                                onConfirm={() => deleteUser(record.key)
                                }
                                okText='Delete'
                                cancelText='Cancel'
                            >
                                <Button type='primary' danger>Delete</Button>
                            </Popconfirm>
                        }
                        <br />
                        {
                            !record.isActivated && <Popconfirm
                                placement='top'
                                title={'Are you sure to activate this user? An email will be sent to the user to set up the password.'}
                                onConfirm={() => activateUser(record.key)
                                }
                                okText='Activate'
                                cancelText='Cancel'
                            >
                                <Button type='primary' ghost>Activate</Button>
                            </Popconfirm>
                        }

                    </>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActivated',
            key: 'isActivated',
            render: (text) => {
                if (text) {
                    return <Tag color="green">Activated</Tag>
                }
                return (
                    <Tag color="red">Not Activated</Tag>
                );
            },
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            render: (text) => {
                return text.map(t => <><Tag>{t}</Tag><br/></>)
            }
        },
        {
            title: 'OCR Role Id',
            dataIndex: 'ocrRoleId',
            key: 'ocrRoleId',
            hidden: true
        },
        {
            title: 'OCR Role',
            dataIndex: 'ocrRole',
            key: 'ocrRole',
            render: (text) => {
                if (text) {
                    return (
                        <Tag>{text}</Tag>
                    );
                }
                return "";
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Title',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        }
        // {
        //     title: 'Created Time',
        //     key: 'createdTime',
        //     dataIndex: 'createdTime',
        //     render: (text) => {
        //         return (
        //             text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
        //         );
        //     },
        // },
        // {
        //     title: 'Modified Time',
        //     key: 'modifiedTime',
        //     dataIndex: 'modifiedTime',
        //     render: (text) => {
        //         return (
        //             text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
        //         );
        //     },
        // },
    ].filter(item => !item.hidden);

    return (
        <>
            <Spin spinning={loading}>
                <Row className='search-area' gutter={{ lg: 8 }}>
                    <Col span={8}>
                        {ocrRoleName && CreateUserRoles.includes(ocrRoleName) &&
                            <Button type='primary' onClick={showCreateModal}>
                                Create User
                            </Button>
                        }
                    </Col>
                    <Col span={8} offset={8}>
                        <Input
                            id='search-box'
                            onKeyUp={filterByKeyword}
                            addonBefore={<SearchOutlined />}
                            placeholder='Search'
                        />
                    </Col>
                </Row>
                <Table style={{ width: "auto" }} columns={columns} dataSource={filteredData} />
            </Spin>
            <Form
                {...formItemLayout}
                form={createForm}
                name='register'
                onFinish={addUser}
                scrollToFirstError
                loading={createLoading}
            >
                <Modal title="Create User" visible={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel} okText="Create User">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={createLoading}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='firstName'
                                            label='First Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input First Name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="First Name" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='lastName'
                                            label='Last Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Last Name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Last Name" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='email'
                                            label='Email'
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: "The input is not valid E-mail!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='departments'
                                            label='Departments'>
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Departments"
                                                onChange={handleDepartmentChange}
                                                defaultValue={[generalDepartmentId]}
                                                options={departmentOptions}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='ocrRoleId'
                                            label='OCR Role'
                                            extra={roleExtra}>
                                            <Select
                                                placeholder="Select Role"
                                                onChange={handleRoleChange}
                                                options={roleOptions}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='phoneNumber'
                                            label='Phone'
                                        >
                                            <Input placeholder="Phone" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='jobTitle'
                                            label='Job Title'
                                        >
                                            <Input placeholder="Job Title" />
                                        </Form.Item>
                                        {/* <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingStreet'
                                            label='Mailing Street'
                                        >
                                            <Input placeholder="Mailing Street" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingCity'
                                            label='Mailing City'
                                        >
                                            <Input placeholder="Mailing City" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingState'
                                            label='Mailing State'
                                        >
                                            <Input placeholder="Mailing State" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingZip'
                                            label='Mailing Zip'
                                        >
                                            <Input placeholder="Mailing Zip" />
                                        </Form.Item> */}
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
            <Form
                {...formItemLayout}
                form={updateForm}
                name='register'
                onFinish={updateUser}
                scrollToFirstError
                loading={updateLoading}
            >
                <Modal title="Update User" visible={updateModalOpen} onOk={handleUpdateOk} onCancel={handleUpdateCancel} okText="Update User">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={createLoading}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='firstName'
                                            label='First Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input First Name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="First Name" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='lastName'
                                            label='Last Name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Last Name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Last Name" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='email'
                                            label='Email'
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: "The input is not valid E-mail!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='departments'
                                            label='Departments'>
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Departments"
                                                onChange={handleDepartmentChange}
                                                options={departmentOptions}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='ocrRoleId'
                                            label='OCR Role'
                                            extra={roleExtra}>
                                            <Select
                                                placeholder="Select Role"
                                                onChange={handleRoleChange}
                                                options={roleOptions}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='phoneNumber'
                                            label='Phone'
                                        >
                                            <Input placeholder="Phone" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='jobTitle'
                                            label='Job Title'
                                        >
                                            <Input placeholder="Job Title" />
                                        </Form.Item>
                                        {/* <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingStreet'
                                            label='Mailing Street'
                                        >
                                            <Input placeholder="Mailing Street" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingCity'
                                            label='Mailing City'
                                        >
                                            <Input placeholder="Mailing City" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingState'
                                            label='Mailing State'
                                        >
                                            <Input placeholder="Mailing State" />
                                        </Form.Item>
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='mailingZip'
                                            label='Mailing Zip'
                                        >
                                            <Input placeholder="Mailing Zip" />
                                        </Form.Item> */}
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </>
    );
};

export default UsersTab;
